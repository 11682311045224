<script setup lang="ts">
import {TooltipProvider} from "radix-vue";
import {Toaster} from "vue-sonner";

useColorMode();
</script>

<template>
  <ClientOnly>
    <UIPageLoader/>
    <TooltipProvider>
      <UITopnavLayout/>
    </TooltipProvider>
    <Toaster rich-colors/>
  </ClientOnly>
</template>