<script setup lang="ts">
const { menuItems } = useLayout();
const route = useRoute();
</script>

<template>
  <header class="content-wrapper topnav-header">
    <div class="topnav-header-content breakout">
      <slot name="logo" />
      <nav class="topnav-links">
        <NuxtLink
          v-for="(item, index) in menuItems"
          :key="index"
          :to="typeof item.to === 'function' ? item.to(route) : item.to"
          class="topnav-link"
          exact-active-class="topnav-link-active"
        >
          <span class="topnav-link-text">{{ item.name }}</span>
        </NuxtLink>
      </nav>
      <slot name="toolbar" />
    </div>
  </header>
</template>

<style>
.topnav {
  border-bottom: 1px var(--border-color) solid;
  background-color: var(--background);
  width: 100%;

}

.topnav-header-content {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--background);
}

.topnav-header {
  background-color: var(--background);
  position: sticky;
  left: 0;
  top: 0;
  z-index: 50;
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: var(--size-fluid-3);
}

.topnav-links {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.topnav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: 2px;
  border-color: transparent;
  padding: var(--tw-size-3);
  text-align: center;
  transition-property: color, background-color, border-color,
  text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  color: var(--text-muted);
  height: 100%;
}

.topnav-link:hover {
  color: var(--text-muted-hover);
}

.topnav-link-active {
  border-color: var(--Border__Process);
  color: var(--text-color);
}

.topnav-link-active:hover {
  color: var(--text-color);
}

.topnav-link-text {
  font-size: var(--tw-font-size-sm);
}

@media (min-width: 768px) {
  .topnav-header {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .topnav-header {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>